import React from 'react'
import PropTypes from 'prop-types'
import { InputBox } from './'

/**
 * Displays subcalculator for Non-Eco Dis, Eco Dis, and Special
 * Ed groups.
 */
function SubCalculator ({ title, values, category, handleChange }) {

  // Bonuses are never negative.
  let displayBonus = Math.max(values.bonus, 0)
  let boxes = ['grads', 'ccmr', 'threshold']
  let headers = {
    grads: 'Number of Graduates',
    ccmr: 'Number of CCMR Graduates',
    threshold: 'Threshold Percent'
  }
  return (
    <div className={'cell score-input small-12 medium-6'}>
      <h6>{title + ' Students'}</h6>
      {boxes.map((name, n) => 
        <div key={name + n}>
          <p>{headers[name]}</p>
          <InputBox
            category={category}
            handleChange={handleChange}
            name={name}
            value={values[name]}
          />
        </div>
      )}
      {/* <input
        type={'text'}
        value={values.grads}
        id={category + 'grads'}
        onChange={(e) => handleChange(category, 'grads', e.target.value)}
        onClick={e => e.target.select()}
      />
      <p>Number of CCMR Graduates</p>
      <input
        type={'text'}
        value={values.ccmr}
        id={category + 'ccmr'}
        onChange={(e) => handleChange(category, 'ccmr', e.target.value)}
        onClick={e => e.target.select()}
      />
      <p>Threshold Percent</p>
      <input
        type={'text'}
        value={values.threshold}
        id={category + 'threshold'}
        onChange={(e) => handleChange(category, 'threshold', e.target.value)}
        onClick={e => e.target.select()}
      /> */}
      <h5>Bonus: ${displayBonus}</h5>
    </div>
  )
}

const { string, number, func, shape, oneOfType, } = PropTypes
SubCalculator.propTypes = {
  title: string.isRequired,
  values: shape({
    grads: oneOfType([number, string]).isRequired,
    ccmr: oneOfType([number, string]).isRequired,
    threshold: oneOfType([number, string]).isRequired,
  }).isRequired,
  category: string.isRequired,
  handleChange: func.isRequired,
}

export default SubCalculator