import React from 'react'
import PropTypes from 'prop-types'

/**
 * Input box that highlights text automatically on click.
 */
function InputBox ({category, value, name, handleChange}) {
	return (
		<input 
			type="text"
			value={value}
			id={category + name}
			onChange={e => handleChange(category, name, e.target.value)}
			onClick={e => e.target.select()}
		/>
	)
}

const { func, number, string, oneOfType } = PropTypes
InputBox.propTypes = {
	category: string.isRequired,
	value: oneOfType([number, string]).isRequired,
	name: string.isRequired,
	handleChange: func.isRequired,
}
export default InputBox