import React from 'react'
import { MainContainer, } from './containers'

function App() {
  return (
    <MainContainer />
  );
}

export default App;
