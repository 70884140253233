import React from 'react'
import PropTypes from 'prop-types'
import { SubCalculator } from './'
import Drift from 'react-driftjs'

/**
 * Main view for the CCMR Outcomes Bonus Calculator
 */
function Main({ ecoDis, nonEcoDis, specialEd, handleCalcChange }) {

  const ABOUT_THIS = `This tool uses the latest information provided
                      by TEA to help you predict the amount of the
                      CCMR Outcomes Bonus your district will start
                      receiving in April 2023 as a result of HB 3.`

  const HOW_TO_USE = `Enter your district's information to
                      predict your CCMR Outcomes Bonus. Change the
                      numbers and the bonus prediction updates in
                      real time. No data is stored. Please note that
                      you should count special education students twice
                      when using this tool. Each special education graduate
                      should be counted in either the Economically Disadvantaged
                      or Non-Economically Disadvantaged category, and then also
                      in the Special Education category.`

  const HELP_MSG = `We have designed this tool to help you make
                      the most of your new CCMR funding. We're here
                      to help. MasteryPrep is not affiliated with or
                      endorsed by TEA. We try to keep this tool up to
                      date. Please refer to the TEA website for 
                      authoritative information on the CCMR outcomes 
                      bonus and HB 3.`

  function sumBonus(ecoDis, nonEcoDis, specialEd) {
    const sum = ecoDis.bonus + nonEcoDis.bonus + specialEd.bonus
    return sum
  }

  const totalBonus = sumBonus(ecoDis, nonEcoDis, specialEd)

  return (
    <div>
      <Drift appId="zfaxdcxsvs98" />      
      <div className="grid-container header contact-info">
        <div className="grid-x grid-padding-x align-middle">
          <div className="cell logo small-8 small-offset-2 medium-4 medium-offset-0 large-2">
            <a href="http://masteryprep.com" target="_blank" rel="noopener noreferrer">
              <img src="http://masteryprep.com/wp-content/themes/MasteryPrep/assets/img/images/masteryprep-logo.svg" alt="MasteryPrep Logo" />
            </a>
          </div>
          <div className="cell call-us small-12 medium-8 medium-offset-0 large-6 large-offset-4">
            <p className="text-center medium-text-right">Have questions? <br className="show-for-small" />We're here to help at <a href="tel:8559228773">855-922-8773</a>
            </p>
          </div>
        </div>
      </div>


      <div className={'grid-container'}>

        <div className="grid-x grid-padding-x align-middle">
          <div className="cell page-title small-12">
            <h2 className="text-center">Texas CCMR Outcomes Bonus Calculator</h2>
          </div>
        </div>
        <div className="grid-x grid-margin-x">
          <div className="cell about-this-guide small-12">
            <div className="callout">
              <div>
                <h3 className='text-center large-text-left'>About This Tool</h3>
                <p>{ABOUT_THIS}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="grid-x grid-margin-x">
          <div className="cell introduction small-12">
            <h4 className="text-center large-text-left">How to Use:</h4>
            <p>{HOW_TO_USE}</p>
          </div>
        </div>
        <div className='grid-x grid-margin-x data-input'>
          <div className="cell enter-institution-data small-12 medium-10 medium-offset-1">
            <div className="cell small-12">
              <h3 className="title text-center medium-text-left">
                Total Predicted CCMR Outcomes Bonus: ${totalBonus}
              </h3>
            </div>
            <div className="inner">
              <div className="grid-x grid-margin-x">
                <SubCalculator
                  title='Economically Disadvantaged'
                  values={ecoDis}
                  handleChange={handleCalcChange}
                  category='ecoDis'
                />
                <SubCalculator
                  title='Non-Economically Disadvantaged'
                  values={nonEcoDis}
                  handleChange={handleCalcChange}
                  category='nonEcoDis'
                />
                <SubCalculator
                  title='Special Education'
                  values={specialEd}
                  handleChange={handleCalcChange}
                  category='specialEd'
                />
              </div>
            </div>
          </div>
        </div>
        <footer className="grid-x having-trouble-container grid-margin-x">
          <div className="cell having-trouble small-12">
            <div className="grid-x grid-margin-x align-middle medium-margin-collapse">
              <div className="cell small-12 large-1 text-center show-for-large">
                <img className="warning-icon" src="http://planner.masteryprep.com/static/media/warning-icon.1c61cef8.svg" alt="" />
              </div>
              <div className="cell small-12 large-11">
                <h4>Running into problems using this tool?</h4>
              </div>
              <div className="cell small-12 warning-instructions large-12">
                <p>{HELP_MSG}</p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

const { func, shape, number, string, oneOfType } = PropTypes
Main.propTypes = {
  ecoDis: shape({
    bonus: number.isRequired,
    grads: oneOfType([number, string]).isRequired,
    ccmr: oneOfType([number, string]).isRequired,
    threshold: oneOfType([number, string]).isRequired,
  }),
  nonEcoDis: shape({
    bonus: number.isRequired,
    grads: oneOfType([number, string]).isRequired,
    ccmr: oneOfType([number, string]).isRequired,
    threshold: oneOfType([number, string]).isRequired,
  }),
  specialEd: shape({
    bonus: number.isRequired,
    grads: oneOfType([number, string]).isRequired,
    ccmr: oneOfType([number, string]).isRequired,
    threshold: oneOfType([number, string]).isRequired,
  }),
  handleCalcChange: func.isRequired,
}

export default Main