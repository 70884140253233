import { useState } from 'react'

/**
 * Handles set of three data points in a category:
 *  Number of Graduates
 *  Number of Graduates Meeting CCMR Criteria
 *  Threshold
 * @param {number} thresholdDefault default threshold
 * @param {number} bonusMultiplier the amount of the bonus
 * @param {number} gradsDefault default graduates number (optional)
 * @param {number} ccmrDefault default graduates ccmr eligible number (optional)
 */
export default function useSubCalc (
  thresholdDefault, 
  bonusMultiplier,
  gradsDefault = 0, 
  ccmrDefault = 0
) {
  const [ grads, setGrads ] = useState(gradsDefault)
  const [ ccmr, setCcmr ] = useState(ccmrDefault)
  const [ threshold, setThreshold ] = useState(thresholdDefault)

  // The bonus in each category is
  // (# of Graduates Meeting CCMR Criteria - (# of Graduates * Threshold)) * Bonus Amount
  function bonusCalc (grads, ccmr, threshold, bonusMultiplier) {
    const thresholdGrads = Math.round(grads * threshold / 100)
    const bonus = Math.round((ccmr - thresholdGrads) * bonusMultiplier)
    
    // if they have text in an input box, just return 0
    if (Number.isNaN(bonus)) return 0
    return Math.max(bonus, 0)
  }

  return {
    values: {
      grads,
      ccmr,
      threshold,
      bonus: bonusCalc(grads, ccmr, threshold, bonusMultiplier),
    },
    setters: {
      grads: setGrads,
      ccmr: setCcmr,
      threshold: setThreshold,
    },
  }
}

