import { useSubCalc } from './'

/**
 * Manages state for the calculator. Split into three
 * subcalculators, one for each of the following three groups:
 * Economically disadvantaged students, non-eco dis, and special
 * ed. 
 */
export default function useCalc () {
  const ecoDis = useSubCalc(11, 5000)
  const nonEcoDis = useSubCalc(24, 3000)
  const specialEd = useSubCalc(0, 2000)
  return {
    ecoDis,
    nonEcoDis,
    specialEd,
  }
}