import React from 'react'
import { Main } from '../components'
import { useCalc } from '../hooks'

/**
 * Main container for CCMR Outcomes Bonus calculator
 */
function MainContainer () {

  /*
    The user needs to enter three categories of data:
    ECONOMICALLY DISADVANTAGED
      # of Graduates
      # of Graduates Meeting CCMR Criteria
      Threshold (Defaults to 9%)
    NON-ECONOMICALLY DISADVANTAGED
      # of Graduates
      # of Graduates Meeting CCMR Criteria
      Threshold (Defaults to 20%)
    SPECIAL EDUCATION
      # of Graduates
      # of Graduates Meeting CCMR Criteria
      Threshold (Defaults to 0%)
    The bonus in each category is
      (# of Graduates Meeting CCMR Criteria - (# of Graduates * Threshold)) * Bonus Amount
      The Bonus Amount for Each Category
        ECONOMICALLY DISADVANTAGED ($5000)
        NON-ECONOMICALLY DISADVANTAGED ($3000)    
        SPECIAL EDUCATION ($2000)
  */

  const calc = useCalc()

  /**
   * Sets new calculator values based on user input. 
   * Uses category and data to figure out what value to change.
   * @param {string} category ecoDis, nonEcoDis, or specialEd
   * @param {string} data grads, ccmr, or threshold
   * @param {number|string} value what the user's setting the input to
   */
  const handleCalcChange = (category, data, value) => {
    calc[category].setters[data](value)
  }

  return (
    <Main 
      ecoDis={calc.ecoDis.values}
      nonEcoDis={calc.nonEcoDis.values}
      specialEd={calc.specialEd.values}
      handleCalcChange={handleCalcChange}
    />
  )
}

export default MainContainer